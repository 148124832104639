/** @jsxImportSource @emotion/react */

import { Typography } from '@vc-workspace/utils-typography';
import Link from 'next/link';
import {containerStyles, imgStyles, homepageCTA} from './something-went-wrong.module.style';
/* eslint-disable-next-line */
export interface SomethingWentWrongProps {
  cta?: string
}

export function SomethingWentWrong(props: SomethingWentWrongProps) {
  return (
    <div css={containerStyles}>
      <div><img src="https://www.vibecity.com/_mp-images/500_error.png" css={imgStyles} alt="" /></div>
      <div className='text-center'>
        <Typography type="Headline_H1" color="heavy_metal">
          We apologise and are fixing the problem.
        </Typography>
        <Typography type="Headline_H2" color="heavy_metal" className='pt-2'>
          Please try after some time.
        </Typography>
        <Link href="/" passHref>
          <button css={homepageCTA}>{props.cta ? props.cta : "Explore Vibecity"}</button>
        </Link>
      </div>
    </div>
  );
}

export default SomethingWentWrong;
